import React from 'react'
import styled, { css } from 'styled-components'

import { Content, Heading, WithLine } from 'components/shared/Typography'
import LazyImage from 'components/shared/LazyImage'
import Container from 'components/shared/Container'
import ArrowButton from 'components/shared/Button/Arrow'

import useSlider from 'hooks/useSlider'

import type Image from 'types/image'

type Props = {
  title: string
  text: string
  image: Image
  gallery: Image[]
}

const Section = styled.section`
  position: relative;
  padding-bottom: 6rem;
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: ${({ theme }) => theme.colors.white50};
    z-index: -1;
  }
`

const Wrapper = styled.div`
  display: flex;
  ${({ theme }) => theme.media.md.max} {
    flex-direction: column;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  padding-top: 2rem;
  ${({ theme }) => theme.media.md.min} {
    padding-top: 6rem;
  }
`

const ContentInnerWrapper = styled.div`
  max-width: 500px;
  margin: 0 auto;
  ${({ theme }) => theme.media.xl.min} {
    padding-left: 3rem;
  }
`

const Gallery = styled.div`
  position: relative;
  width: 100%;
  max-height: 1280px;
  ${({ theme }) => theme.media.md.max} {
    height: 500px;
  }
`

const ContentBlock = styled.div`
  padding: 1.5rem 0;
  ${({ theme }) => theme.media.lg.min} {
    padding: 2rem;
  }
`

const ImageWrapper = styled.div<{ active: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.6s;
  visibility: hidden;
  opacity: 0;

  ${({ active }) =>
    active &&
    css`
      visibility: visible;
      opacity: 1;
    `}
`

const Controls = styled.div`
  position: absolute;
  bottom: -25px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: flex-start;
  ${({ theme }) => theme.media.md.max} {
    justify-content: center;
  }
  & > button {
    background: ${({ theme }) => theme.colors.white};
    span,
    img {
      filter: brightness(0);
    }
    &:hover {
      background: ${({ theme }) => theme.colors.primary100};
      border-color: ${({ theme }) => theme.colors.primary100};
    }
  }
`

const OfferAbout: React.FC<Props> = ({ title, text, image, gallery }) => {
  const { activeSlide, goNext, goPrev } = useSlider(gallery)
  return (
    <Section>
      <Wrapper>
        <ContentWrapper>
          <Container>
            <ContentInnerWrapper>
              <ContentBlock>
                <WithLine>
                  <Heading
                    as="h2"
                    dangerouslySetInnerHTML={{ __html: title }}
                    size={50}
                    weight={600}
                    sizeDiff={0.7}
                    line="1.1"
                    margin="0"
                  />
                </WithLine>
              </ContentBlock>
              <LazyImage src={image.src} alt={image.alt ?? ''} />
              <ContentBlock>
                <Content dangerouslySetInnerHTML={{ __html: text }} />
              </ContentBlock>
            </ContentInnerWrapper>
          </Container>
        </ContentWrapper>
        <Gallery>
          {gallery.map(({ src, alt }, index) => (
            <ImageWrapper active={activeSlide === index} key={index}>
              <LazyImage src={src} alt={alt ?? ''} fill />
            </ImageWrapper>
          ))}
          <Controls>
            <ArrowButton onClick={goPrev} direction="left" />
            <ArrowButton onClick={goNext} direction="right" />
          </Controls>
        </Gallery>
      </Wrapper>
    </Section>
  )
}

export default OfferAbout
