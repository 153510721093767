import React, { useMemo } from 'react'
import { PageProps, graphql } from 'gatsby'

import Layout from 'components/layout/Layout'
import SEO from 'components/layout/SEO'
import Breadcrumbs from 'components/layout/Breadcrumbs'

// import Header from 'components/layout/Headers/Secondary'
import HeaderFilters from 'components/layout/Headers/Homepage/Filters'
import Investments from 'components/layout/Sections/Investments/Simple'
import Map from 'components/layout/Maps'
import OfferAbout from 'components/layout/Sections/FlatsOffer/About'

import useFilters from 'hooks/useFilters'

import filterOutNulls from 'utils/filterOutNulls'

import type { OfferApartmentsPageQuery } from 'types/graphql'
import type { InvestmentFilters } from 'types/filters'
import type { InvestmentCardSimple } from 'types/investments'
import type { FlatSimple } from 'types/flat'
import type { InvestmentPin } from 'components/layout/Maps/InvestmentPin'
import type Image from 'types/image'

const OfferApartmentsPage: React.FC<PageProps<OfferApartmentsPageQuery>> = ({
  data,
}) => {
  const PAGE = data?.page?.pageFindApartment
  const PAGE_SEO = data?.page?.seo
  const OFFER = data.IN_SALE?.investments?.nodes
  const FILTERS = data.INVESTMENT_FILTERS.nodes as InvestmentFilters[]
  const ALL_FLATS = data.allFlats.nodes as FlatSimple[]

  if (!PAGE || !PAGE_SEO || !OFFER)
    throw new Error("CMS data didn't load properly")

  const INVESTMENTS: InvestmentCardSimple[] = useMemo(
    () =>
      OFFER.map((investment) => {
        const allInvestmentFlats = ALL_FLATS.filter(
          (flat) => flat.investment === investment?.title
        )
        const availableInvestmentFlatsNumber = allInvestmentFlats
          .filter((flat) => flat.status === 'available')
          .length.toString()

        return {
          image: {
            src: investment?.investmentFields?.investmentMapImg?.localFile
              ?.childImageSharp?.gatsbyImageData!,
            alt: investment?.investmentFields?.investmentMapImg?.altText!,
          },
          name: investment?.title!,
          description: investment?.investmentFields?.investmentTeaser!,
          link: `/${investment?.slug}`,
          availableFlats: `${availableInvestmentFlatsNumber}/${allInvestmentFlats.length}`,
          tag: {
            text: investment?.investmentFields?.investmentTagText!,
            color: investment?.investmentFields?.investmentTagColor!,
          },
        }
      }) || [],
    []
  )

  const MAP_INVESTMENTS: InvestmentPin[] = useMemo(
    () =>
      OFFER.map((investment) => ({
        name: investment?.title!,
        image:
          investment?.investmentFields?.investmentMapImg!.localFile!
            .childImageSharp!.gatsbyImageData!,
        lat: Number(
          investment?.investmentFields?.investmentMap?.investmentMapLat
        ),
        lng: Number(
          investment?.investmentFields?.investmentMap?.investmentMapLng
        ),
        position: investment!.investmentFields?.investmentMap
          ?.investmentMapBoxDirection as InvestmentPin['position'],
        link: `/${investment?.slug}`,
      })) || [],
    []
  )

  const OFFER_ABOUT_GALLERY: Image[] = useMemo(
    () =>
      PAGE.findApartmentPageS3Slider?.filter(filterOutNulls).map((image) => ({
        src: image!.findApartmentPageS3SliderElementImg!.localFile!
          .childImageSharp!.gatsbyImageData!,
        alt: image!.findApartmentPageS3SliderElementImg!.altText!,
      })) || [],
    []
  )

  const [filteredInvestments, { fields, filters, applyFilters, setFilters }] =
    useFilters('investment', { items: FILTERS })

  return (
    <Layout background="white50">
      <SEO seo={PAGE_SEO} />
      <Breadcrumbs crumbs={[{ name: 'Oferta mieszkań' }]} />
      {/* <Header
        title={PAGE.findApartmentPageS1Title!}
        image={{
          src: PAGE.findApartmentPageS1Img?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE.findApartmentPageS1Img?.altText!,
        }}
      > */}
      <HeaderFilters
        includedFilters={fields}
        filters={filters}
        applyFilters={applyFilters}
        setFilters={setFilters}
        filtersOffer
      />
      {/* </Header> */}
      <main>
        <Investments
          investments={INVESTMENTS.filter((investment) =>
            filteredInvestments.some((el) => el.investment === investment.name)
          )}
          offerPage
        />
        <Map
          center={{
            lat: Number(
              PAGE.findApartmentPageS2Map?.findApartmentPageS2MapMarkersLat
            ),
            lng: Number(
              PAGE.findApartmentPageS2Map?.findApartmentPageS2MapMarkersLng
            ),
          }}
          investments={MAP_INVESTMENTS}
        />
        <OfferAbout
          title={PAGE.findApartmentPageS3Title!}
          text={PAGE.findApartmentPageS3Description!}
          image={{
            src: PAGE.findApartmentPageS3Img?.localFile?.childImageSharp
              ?.gatsbyImageData!,
            alt: PAGE.findApartmentPageS3Img?.altText!,
          }}
          gallery={OFFER_ABOUT_GALLERY}
        />
      </main>
    </Layout>
  )
}

export default OfferApartmentsPage

export const query = graphql`
  query OfferApartmentsPage($locale: String) {
    INVESTMENT_FILTERS: allFilters {
      nodes {
        investment
        location
        area {
          min
          max
        }
        rooms
        floor
        type
      }
    }
    IN_SALE: wpInvestmentCategory(slug: { eq: "inwestycja-w-sprzedazy" }) {
      investments {
        nodes {
          title
          slug
          investmentFields {
            investmentTeaser
            investmentTagText
            investmentTagColor
            investmentMapImg {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                  )
                }
              }
            }
            investmentMap {
              investmentMapLng
              investmentMapLat
              investmentMapBoxDirection
            }
          }
        }
      }
    }
    allFlats: allFlats {
      nodes {
        investment
        status
      }
    }
    page: wpPage(
      language: { slug: { eq: $locale } }
      slug: { regex: "/oferta-mieszkan/" }
    ) {
      seo {
        ...WpSEO
      }
      pageFindApartment {
        findApartmentPageS1Title
        findApartmentPageS1Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1100
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        findApartmentPageS2Map {
          findApartmentPageS2MapMarkersLng
          findApartmentPageS2MapMarkersLat
        }
        findApartmentPageS3Title
        findApartmentPageS3Description
        findApartmentPageS3Img {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 800
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        findApartmentPageS3Slider {
          findApartmentPageS3SliderElementImg {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  height: 1280
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`
