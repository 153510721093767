import React from 'react'
import styled, { css } from 'styled-components'
import { Heading } from 'components/shared/Typography'
import Filters from 'components/layout/Filters'

import type { FilterField, FiltersState } from 'types/filters'

type Props = {
  filters: FiltersState
  includedFilters: FilterField[]
  setFilters: (filters: FiltersState) => void
  applyFilters: () => void
  filtersHome?: boolean
  filtersOffer?: boolean
}

type WrapperProps = {
  filtersHome?: boolean
  filtersOffer?: boolean
}

const Wrapper = styled.div<WrapperProps>`
  ${({ filtersHome, filtersOffer }) =>
    !filtersHome &&
    !filtersOffer &&
    css`
      ${({ theme }) => theme.media.lg.min} {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        margin: 0 auto;
        z-index: 10;
        top: calc(100% - 50px);
      }
    `}
`

const StyledHeading = styled(Heading)`
  margin-top: 2rem;
  ${({ theme }) => theme.media.md.min} {
    display: block;
  }
`

const HeaderFilters: React.FC<Props> = ({
  filters,
  includedFilters,
  setFilters,
  applyFilters,
  filtersHome,
  filtersOffer,
}) => {
  return (
    <Wrapper filtersHome={filtersHome} filtersOffer={filtersOffer}>
      {filtersHome && (
        <StyledHeading
          as="h5"
          size={30}
          sizeDiff={0.5}
          weight={500}
          align="center"
        >
          Konfigurator mieszkań
        </StyledHeading>
      )}
      <Filters
        filters={filters}
        includedFilters={includedFilters}
        setFilters={setFilters}
        applyFilters={applyFilters}
        filtersHome={filtersHome}
      />
    </Wrapper>
  )
}

export default HeaderFilters
